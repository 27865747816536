
.upload-image-btn {
  box-shadow: 0 0 10px 10px rgba(36, 39, 49, 0.6);
}

.styled-header {
  color: rgb(1, 151, 221)
}

.top-nav-link {
  color: white;
  font-size: 20px;
  text-decoration-line: none;
  cursor: grab;
  background-color: transparent;
  border: none;
}